






















import { Component, Vue, Prop } from "vue-property-decorator";
import BaseModal from "@/layouts/components/BaseModal.vue";
import { showErrorAlert } from "@/helpers";

@Component({
	components: {
		BaseModal,
	},
})
export default class SolutionCardFileModal extends Vue {
	@Prop({ default: false }) isOpen: boolean;
	@Prop({ default: () => null }) onModalClosed!: () => void;
	@Prop() fileData!: { name: string; url: string };

	async downloadFile() {
		try {
			const response = await fetch(this.fileData.url);
			const blob = await response.blob();
			const fileUrl = URL.createObjectURL(blob);

			const a = document.createElement("a");
			a.href = fileUrl;
			a.download = `${this.fileData.name}.${this.getFileExtension}`;
			a.click();
			URL.revokeObjectURL(fileUrl);
		} catch (e) {
			showErrorAlert("Ocorreu um erro. Tente novamente.");
		}
	}

	get getFileExtension() {
		const extension = this.fileData.url.split(".").pop();
		if (!extension) {
			return "";
		}
		return extension;
	}
}
