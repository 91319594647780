var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chatMessage.mediaFiles[0].indexOf('http') === 0)?_c('div',{staticClass:"w-full"},[(['jpeg', 'jpg', 'png', 'webp'].includes(_vm.mediaExtension))?_c('div',{staticClass:"w-full cursor-pointer flex justify-center items-center"},[_c('img',{staticStyle:{"max-height":"150px","margin-bottom":"10px","border-radius":"5px"},attrs:{"src":_vm.chatMessage.mediaFiles[0],"onerror":"this.style.display='none'"},on:{"click":function($event){return _vm.openFile(_vm.chatMessage.mediaFiles[0])}}})]):(_vm.mediaExtension === 'mp4')?_c('div',{staticClass:"w-full cursor-pointer relative justify-center items-center flex mb-0"},[_c('div',{staticClass:"flex items-center justify-center absolute w-full h-full pb-0.5"},[_c('div',{staticClass:"bg-journey-green rounded-3xl justify-center items-center"},[_c('GuideBalloon',{staticClass:"text-journey-white",class:'p-0.5',attrs:{"featherIcon":{
						icon: 'PlayIcon',
						size: '36',
					},"isStaticBalloon":true,"iconStyle":'h-5 font-bold'}})],1)]),_c('video',{staticStyle:{"max-height":"150px","margin-bottom":"10px","border-radius":"5px"},attrs:{"src":_vm.chatMessage.mediaFiles[0],"onerror":"this.style.display='none'"},on:{"click":function($event){return _vm.openFile(_vm.chatMessage.mediaFiles[0])}}})]):(['ogg', 'mp4', 'mpeg', 'opus', 'mp3'].includes(_vm.mediaExtension))?_c('div',{staticClass:"w-full cursor-pointer"},[_c('div',{staticClass:"flex py-0.5"},[_c('audio',{attrs:{"controls":""}},[_c('source',{staticStyle:{"max-height":"150px","margin-bottom":"10px","border-radius":"5px"},attrs:{"src":_vm.chatMessage.mediaFiles[0],"type":"audio/ogg"}})])])]):(_vm.mediaExtension === 'pdf')?_c('div',{staticClass:"cursor-pointer flex justify-center items-center"},[_c('div',{staticClass:"flex w-full min-h-60 items-center justify-center",on:{"click":function($event){return _vm.openFile(_vm.chatMessage.mediaFiles[0])}}},[_c('GuideBalloon',{class:'px-0.5 red-alert',attrs:{"featherIcon":{
					icon: 'FileTextIcon',
					size: '30',
					style: 'width: 25px; height: 25px',
				},"isStaticBalloon":true,"iconStyle":'h-5 font-bold'}})],1)]):(_vm.mediaExtension === 'vcard')?_c('div',[_c('a',{attrs:{"href":_vm.chatMessage.mediaFiles[0]}},[_vm._v("Baixar arquivo de contato")])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }