






























































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
	getDaysFromMonth,
	IWeekday,
	weekdays,
	toIsoDate,
	getWeekdayFromMonth,
	STABLE_WEEK_INDEX,
	STABLE_WEEKDAY_INDEX,
	isBetween,
} from "@/helpers/calendar";
import { addMonths, format, subDays } from "date-fns";
import { IRange } from "@/interfaces/range";
import { showErrorAlert } from "@/helpers";
import SolutionAppointmentAppModel from "@/api/solution_appointment_app.model";
import { AppointmentStatus, APPOINTMENTS_PER_DAY, Days, IAppointment } from "@/interfaces/appointment";

const realWorldMonthIndex = new Date().getMonth();
const currentTimezone = new Date().getTimezoneOffset() / 60;
const utcDatePart = `T${String(currentTimezone).padStart(2, "0")}:00:00Z`;

//load x months from now to calendar.vue
const monthsToBeLoaded = Array.from({ length: 12 }, (_, index) => getDaysFromMonth(realWorldMonthIndex + index));

@Component
export default class Calendar extends Vue {
	@Prop() calendarStyle!: string;
	@Prop() calendarEnclosureStyle!: string;
	@Prop({ default: "" }) value!: string;
	@Prop({ default: false }) isOpen!: boolean;
	@Prop({ default: false }) isWeekView!: boolean;
	@Prop({ default: false }) isModalLike!: boolean;
	@Prop({ default: false }) preventNonWorkDays!: boolean;
	@Prop() absoluteOptions!: { nextTo: string; offset: { x: number; y: number } };
	@Prop({ default: () => new Set() }) highlightedDates!: Set<string>;
	@Prop({ default: () => [] }) disabledDateRanges!: IRange[];
	@Prop({ default: 0 }) numberOfYearsToRetroact!: number;
	@Prop({ default: 12 }) monthsToBeLoadedLength!: number;
	@Prop({ default: false }) canShowAppointments!: boolean;

	months: IWeekday[][][] = JSON.parse(JSON.stringify(monthsToBeLoaded));
	appointmentsPerDay = APPOINTMENTS_PER_DAY;

	currentMonthIndex = 0;
	currentWeekIndex = 0;
	weekdays = weekdays;
	activeUser = this.$store.state.auth.activeUser;

	lastSelectedWeekday: { monthIndex: number; weekIndex: number; weekdayIndex: number } | null = null;
	lastSelectedDate = "";

	@Watch("value")
	handleDateChange() {
		if (!this.value) {
			this.unselectLastSelectedDay();
			this.$emit("input", this.value);
			//prevent self-update on $emit("input")
		} else if (this.value !== this.lastSelectedDate) {
			this.selectDayByDate(this.value);
		}
	}

	@Watch("isOpen")
	async setAbsoluteCalendarPosition() {
		if (this.absoluteOptions && this.isOpen) {
			const calendar: HTMLElement = this.$refs.calendar as any;
			const { nextTo, offset } = this.absoluteOptions;
			const { top, left } = document.getElementById(nextTo)!.getBoundingClientRect();
			const { x = 0, y = 0 } = offset ?? {};
			const { scrollTop: pageScrollTop, scrollLeft: pageScrollLeft } = document.documentElement;
			const finalLeft = left + x + pageScrollLeft;
			const finalTop = top + y + pageScrollTop;

			Object.assign(calendar!.style, {
				left: `${finalLeft}px`,
				top: `${finalTop}px`,
			});
		}
		await this.listAppointments();
	}

	created() {
		//[months[weeks[day]]]
		this.months =
			this.numberOfYearsToRetroact > 0 && this.monthsToBeLoadedLength > 12
				? JSON.parse(
						JSON.stringify(
							Array.from({ length: this.monthsToBeLoadedLength }, (_, index) =>
								getDaysFromMonth(
									realWorldMonthIndex + index,
									new Date().getFullYear() - this.numberOfYearsToRetroact,
								),
							),
						),
				  )
				: JSON.parse(JSON.stringify(monthsToBeLoaded));

		this.setDisabledDates();
		this.selectDayByDate(this.value);
	}

	get translatedMonth() {
		return this.months[this.currentMonthIndex][STABLE_WEEK_INDEX][STABLE_WEEKDAY_INDEX].translatedMonth;
	}

	get year() {
		return this.months[this.currentMonthIndex][STABLE_WEEK_INDEX][STABLE_WEEKDAY_INDEX].year;
	}

	get month() {
		return this.months[this.currentMonthIndex][STABLE_WEEK_INDEX][STABLE_WEEKDAY_INDEX].realMonthIndex;
	}

	get todayIsoDate() {
		return toIsoDate(new Date());
	}

	get monthsLength() {
		return this.months.length;
	}

	get firstMonthIndex() {
		return this.months[0][STABLE_WEEK_INDEX][STABLE_WEEKDAY_INDEX].realMonthIndex!;
	}

	get isPreviousButtonDisabled() {
		const isFirstMonth = this.currentMonthIndex === 0;
		return !this.isWeekView ? isFirstMonth : isFirstMonth && this.currentWeekIndex === 0;
	}

	get isNextButtonDisabled() {
		const isLastMonth = this.currentMonthIndex === this.monthsLength - 1;
		const isLastWeek = this.currentWeekIndex === this.months[this.monthsLength - 1].length - 1;
		return !this.isWeekView ? isLastMonth : isLastMonth && isLastWeek;
	}

	setDisabledDates() {
		this.months.flat(2).forEach(weekday => {
			const date = this.buildDateStringFromWeekdayObject(weekday, true);

			const isWeekdayDisabled =
				(this.preventNonWorkDays && !weekday.isWorkDay) ||
				this.isEdgeDay(weekday) ||
				this.disabledDateRanges.some(range => isBetween(date, range));

			if (isWeekdayDisabled) {
				weekday.isDisabled = true;
			}
		});
	}

	getSectionDataByIndexes(monthIndex: number, weekIndex: number) {
		const sectionId = `${monthIndex}${weekIndex}`;
		const currentMonth = this.months[monthIndex];
		let isWholeSectionDisabled = false;
		let correctStartWeekSection;
		let correctEndWeekSection;

		if (this.isWeekView) {
			correctStartWeekSection = weekIndex;
			correctEndWeekSection = weekIndex;
			isWholeSectionDisabled = !currentMonth[weekIndex].some(({ isDisabled }) => !isDisabled);
		} else {
			const weeksLength = currentMonth.length;
			correctStartWeekSection = 0;
			correctEndWeekSection = weeksLength - 1;
			isWholeSectionDisabled = !currentMonth.flat(1).some(({ isDisabled }) => !isDisabled);
		}

		let firstWeekday = { ...currentMonth[correctStartWeekSection][0] };
		let lastWeekday = { ...currentMonth[correctEndWeekSection][6] };
		[firstWeekday, lastWeekday] = this.updateFirstAndLastMonthDates(firstWeekday, lastWeekday);

		if (Number(lastWeekday.day) < 30) {
			//lastDay.day + 1 because it will represent the very end of the current day, or the very start of the next one
			lastWeekday.day = String(Number(lastWeekday.day) + 1).padStart(2, "0");
		}
		const start = this.buildDateStringFromWeekdayObject(firstWeekday, true);
		const end = this.buildDateStringFromWeekdayObject(lastWeekday, true);

		return { range: { start, end }, sectionId, isWholeSectionDisabled };
	}

	updateFirstAndLastMonthDates(firstWeekDate: IWeekday, lastWeekDate: IWeekday) {
		const currentYear = new Date().getFullYear();

		if (firstWeekDate.relatedMonth === "previous") {
			firstWeekDate.month = String(Number(firstWeekDate.month) - 1).padStart(2, "0");

			if (firstWeekDate.realMonthIndex === 0 && firstWeekDate.year && firstWeekDate.year > currentYear) {
				firstWeekDate = {
					...firstWeekDate,
					month: String(12).padStart(2, "0"),
					realMonthIndex: 11,
					year: firstWeekDate.year - 1,
					translatedMonth: "Dezembro",
				};
			}
		}
		if (lastWeekDate.relatedMonth === "next") {
			lastWeekDate.month = String(Number(lastWeekDate.month) + 1).padStart(2, "0");

			if (lastWeekDate.realMonthIndex === 11 && lastWeekDate.year) {
				lastWeekDate = {
					...lastWeekDate,
					month: String(1).padStart(2, "0"),
					realMonthIndex: 0,
					year: lastWeekDate.year + 1,
					translatedMonth: "Janeiro",
				};
			}
		}

		return [firstWeekDate, lastWeekDate];
	}

	get currentSectionData() {
		return this.getSectionDataByIndexes(this.currentMonthIndex, this.currentWeekIndex);
	}

	get selectedSectionData() {
		if (this.lastSelectedWeekday) {
			const { monthIndex, weekIndex } = this.lastSelectedWeekday;
			return this.getSectionDataByIndexes(monthIndex, weekIndex);
		}
		return null;
	}

	async goToNextCalendarSection() {
		if (this.isWeekView) {
			const currentMonthWeeksLength = this.months[this.currentMonthIndex].length;
			if (this.currentWeekIndex < currentMonthWeeksLength - 1) {
				this.currentWeekIndex++;
			} else if (this.currentMonthIndex < this.monthsLength - 1) {
				this.currentWeekIndex = 0;
				this.currentMonthIndex++;
			}
		} else if (this.currentMonthIndex < this.monthsLength - 1) {
			this.currentMonthIndex++;
		}
		await this.listAppointments();
		this.$emit("calendar-section-change", this.currentSectionData);
	}

	async goToPreviousCalendarSection() {
		if (this.isWeekView) {
			if (this.currentWeekIndex > 0) {
				this.currentWeekIndex--;
			} else if (this.currentMonthIndex > 0) {
				this.currentMonthIndex--;
				const currentMonthWeeksLength = this.months[this.currentMonthIndex].length;
				this.currentWeekIndex = currentMonthWeeksLength - 1;
			}
		} else if (this.currentMonthIndex > 0) {
			this.currentMonthIndex--;
		}
		await this.listAppointments();
		this.$emit("calendar-section-change", this.currentSectionData);
	}

	async listAppointments() {
		if (this.canShowAppointments) {
			this.appointmentsPerDay = JSON.parse(JSON.stringify(APPOINTMENTS_PER_DAY));
			try {
				const startDate = new Date(Number(this.year), this.month, 1);
				const endDate = subDays(addMonths(startDate, 1), 1);

				const { data } = await SolutionAppointmentAppModel.getAppointmentListByDate({
					limit: 1000,
					page: 1,
					isWithReturn: true,
					appointmentStatus: [AppointmentStatus.CONFIRMED],
					userId: this.activeUser.id,
					returnStartDate: startDate.toISOString(),
					returnEndDate: endDate.toISOString(),
				});

				//Solução para contar agendamento por id de cliente por dia
				const appointments: IAppointment[] = Object.values(
					data.reduce((acc: any, appointment: IAppointment) => {
						const key = `${appointment.client.id}-${appointment.returnDate}`;
						if (!acc[key]) {
							acc[key] = appointment;
						}
						return acc;
					}, {}),
				);

				appointments.forEach((appointment: IAppointment) => {
					const returnDate = appointment.returnDate;
					const date = typeof returnDate === "string" ? new Date(returnDate) : returnDate;
					if (date) {
						const day = date.getDate() as Days;
						this.appointmentsPerDay[day]++;
					}
				});
			} catch (e) {
				console.error(e);
				showErrorAlert("Ocorreu um erro. Tente novamente.");
			}
		}
	}

	selectDayByDate(date: string | Date) {
		let shouldGoToTodayDateView = false;
		if (!date) {
			date = this.todayIsoDate;
			shouldGoToTodayDateView = true;
		}

		//validates the date
		if (!Date.parse(<any>date)) {
			console.error("Calendar.vue ~ specified value is not a valid date");
			return;
		}

		if (date instanceof Date) {
			date = format(date, "yyyy-MM-dd");
		} else {
			date = format(new Date(date.split("T").length > 1 ? date : `${date}${utcDatePart}`), "yyyy-MM-dd");
		}
		const [year, month, day] = date.split("-");
		const startMonthIndexOfSpecifiedYear = this.months.findIndex(
			month => month[STABLE_WEEK_INDEX][STABLE_WEEKDAY_INDEX].year === Number(year),
		);

		const rawMonthIndex = Number(month) - 1;
		const rawDayIndex = Number(day) - 1;
		const rawMonthIndexWithCorrectOffset = rawMonthIndex + startMonthIndexOfSpecifiedYear;

		if (rawMonthIndexWithCorrectOffset < 0 || rawMonthIndexWithCorrectOffset > this.monthsLength - 1) {
			//specified month not included on calendar
			return;
		}

		//if the specified month is from a forward year, "startMonthIndexOfSpecifiedYear" is !== 0 and
		//"rawMonthIndex" points to the exact calendar month index
		//otherwise, it's needed subtract the "firstMonthIndex", then the real provided month index
		//will match to the calendar month index
		const monthCorrection = startMonthIndexOfSpecifiedYear === 0 ? this.firstMonthIndex! : 0;
		//translation from real months/days to calendar months/days indexes
		const monthIndex = rawMonthIndexWithCorrectOffset - monthCorrection;
		const dayIndexInMonth = rawDayIndex + getWeekdayFromMonth(rawMonthIndex, 1).weekday;

		const weekIndex = Math.floor(dayIndexInMonth / 7);
		const dayIndexInWeek = dayIndexInMonth - 7 * weekIndex;

		if (shouldGoToTodayDateView) {
			this.currentMonthIndex = monthIndex;
			this.currentWeekIndex = weekIndex;
			return;
		}
		this.selectDay(monthIndex, weekIndex, dayIndexInWeek);
	}

	selectDay(currentMonthIndex: number, currentWeekIndex: number, weekdayIndex: number) {
		if (
			this.lastSelectedWeekday?.monthIndex === currentMonthIndex &&
			this.lastSelectedWeekday?.weekIndex === currentWeekIndex &&
			this.lastSelectedWeekday?.weekdayIndex === weekdayIndex
		) {
			//selected the same option (day)
			return;
		}

		this.unselectLastSelectedDay();

		let weekday = this.months[currentMonthIndex][currentWeekIndex][weekdayIndex];

		// if the selected day is not from the current month view (it's an edge day)
		// example, the months stats in 28, 29, 01, 02... and ends in 30, 31, 01, 02
		if (weekday.relatedMonth === "previous" && currentMonthIndex > 0) {
			//param reassign
			currentMonthIndex--;

			//param reassign
			currentWeekIndex = this.months[currentMonthIndex].length - 1;
			const weeks = this.months[currentMonthIndex][currentWeekIndex];

			for (let length = weeks.length - 1, index = length; index >= 0; index--) {
				const currentWeekDay = weeks[index];
				if (currentWeekDay.day === weekday.day) {
					weekday = currentWeekDay;
					//param reassign
					weekdayIndex = index;
					break;
				}
			}
		}

		if (weekday.relatedMonth === "next" && currentMonthIndex < this.monthsLength - 1) {
			//param reassign
			currentMonthIndex++;

			//param reassign
			currentWeekIndex = 0;
			weekday = this.months[currentMonthIndex][currentWeekIndex].find((currentWeekday, index) => {
				if (currentWeekday.day === weekday.day) {
					//param reassign
					weekdayIndex = index;
					return true;
				}
			})!;
		}

		this.setLastSelectedWeekday(currentMonthIndex, currentWeekIndex, weekdayIndex);
		this.currentMonthIndex = currentMonthIndex;
		this.currentWeekIndex = currentWeekIndex;

		weekday.isSelected = true;
		const isoDate = this.buildDateStringFromWeekdayObject(weekday, true);
		this.lastSelectedDate = isoDate;
		this.updateSelectedDate(isoDate);
	}

	unselectLastSelectedDay() {
		if (this.lastSelectedWeekday) {
			const { monthIndex, weekIndex, weekdayIndex } = this.lastSelectedWeekday;
			this.months[monthIndex][weekIndex][weekdayIndex].isSelected = false;
		}
	}

	setLastSelectedWeekday(monthIndex: number, weekIndex: number, weekdayIndex: number) {
		this.lastSelectedWeekday = {
			monthIndex,
			weekIndex,
			weekdayIndex,
		};
	}

	buildDateStringFromWeekdayObject(weekday: IWeekday, withTimeZone = false): string {
		const timezone = withTimeZone ? utcDatePart : "";
		return `${weekday.year}-${weekday.month}-${weekday.day}${timezone}`;
	}

	isMonthEdgeDay(weekday: IWeekday) {
		return weekday.relatedMonth === "previous" || weekday.relatedMonth === "next";
	}

	//verify if it's an edge day at the very start/end
	isEdgeDay(weekday: IWeekday) {
		const localIndex = weekday.realMonthIndex - realWorldMonthIndex;
		return (
			(localIndex === 0 && weekday.relatedMonth === "previous") ||
			(localIndex === this.monthsLength - 1 && weekday.relatedMonth === "next")
		);
	}

	updateSelectedDate(date: string) {
		this.$emit("input", date);
	}
}
