



























































































































































































































































































































































































































































import { BButton, BFormCheckbox, BFormFile, BFormGroup, BFormInput, BFormTextarea } from "bootstrap-vue";
import { format, formatISO, parse, parseISO } from "date-fns";
import flatPickr from "vue-flatpickr-component";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import vSelect from "vue-select";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/flatpickr.css";
import { FieldDescription } from "@/api/_crud";
import { uploadFile } from "@/api/_request";
import { showErrorAlert, showSuccessAlert } from "@/helpers";
import CustomInput from "@/layouts/components/Input.vue";
import CustomSelect from "@/layouts/components/Select.vue";
import CustomTextArea from "@/layouts/components/TextArea.vue";
import CustomCheckbox from "@/layouts/components/Checkbox.vue";
import MultipleTextInput from "@/layouts/components/MultipleTextInput.vue";
import MultipleSelectButton from "@/layouts/components/MultipleSelectButton.vue";
import AvailabilitySelect from "@/layouts/components/AvailabilitySelect.vue";
import BaseModal from "@/layouts/components/BaseModal.vue";
import Table from "@/layouts/components/Table.vue";
import CustomButton from "@/layouts/components/Button.vue";

@Component({
	components: {
		flatPickr,
		BButton,
		BFormGroup,
		BFormCheckbox,
		BFormFile,
		BFormInput,
		BFormTextarea,
		vSelect,
		CustomInput,
		CustomSelect,
		CustomTextArea,
		CustomCheckbox,
		MultipleTextInput,
		MultipleSelectButton,
		AvailabilitySelect,
		BaseModal,
		Table,
		CustomButton,
	},
})
export default class EntityField extends Vue {
	// Input option 1: field object from entity description
	@Prop() field?: any;

	// Input option 2: full entity description and field key
	@Prop() entityDescription?: FieldDescription[];
	@Prop() fieldKey?: string;
	@Prop() entity: any;
	@Prop({ default: false }) isReadOnly: boolean;
	@Prop({ default: false }) shouldAddPushTagsClass: boolean;
	@Prop({ default: () => null }) onInputChange!: Function;
	@Prop({ default: () => ({}) }) errorsInRequest!: any;

	get flatPickrConfig() {
		return {
			locale: Portuguese,
			enableTime: this.fieldDefinition.kind === "datetime",
			dateFormat: "Z",
			altInput: true,
			altFormat: this.fieldDefinition.kind === "datetime" ? "d/m/Y H:i" : "d/m/Y",
			allowInput: true,
		};
	}

	get timeFlatPickrConfig() {
		return {
			enableTime: true,
			noCalendar: true,
			dateFormat: "H:i",
			time_24hr: true,
		};
	}

	file: any = null;
	password = "password";
	multipleText = [];
	imagePreview: any = "";
	error = "";
	isModalOpen = false;
	itemSelected = {} as any;

	get solutionId() {
		return this.$route.params.id;
	}

	async afterSuccessSendForm() {
		await this.fieldDefinition.customFunctionFormat(this.entity);
		this.modalClosed();
	}

	openAddCorporateClientModal() {
		this.itemSelected = {};
		this.isModalOpen = true;
	}

	modalClosed() {
		this.isModalOpen = false;
	}

	openModal(data?: any) {
		this.itemSelected = data;
		this.isModalOpen = true;
	}

	@Watch("errorsInRequest", { deep: true })
	updateError() {
		if (this.errorsInRequest.errorMessages?.[0]) {
			this.error = this.errorsInRequest.errorMessages?.[0];
		}
	}

	contentOnReadOnly = "";

	validateAndFormatNameAndLastName(property: string) {
		return this.entity[`${property}.name`] && this.entity[`${property}.lastName`]
			? `${this.entity[`${property}.name`]} ${this.entity[`${property}.lastName`]}`
			: "";
	}

	@Watch("entity", { deep: true })
	updateContentOnReadOnly() {
		const currentSolutionId = this.solutionId;
		this.contentOnReadOnly = "";
		//TODO: Deixar mais genérico
		if (
			["isIndividualForClient.id", "loyalUser.id"].includes(this.fieldDefinition.key) &&
			this.entity["id"] === currentSolutionId
		) {
			this.contentOnReadOnly = this.validateAndFormatNameAndLastName(this.fieldDefinition.key.split(".")[0]);
		} else {
			this.contentOnReadOnly = this.fieldDefinition.onTransform
				? this.fieldDefinition.onTransform(this.entity[this.fieldDefinition.key])
				: this.entity[this.transformFieldKeyValue(this.fieldDefinition.key)];
		}
	}

	get fieldDefinition() {
		if (this.field) {
			return this.field;
		}
		if (this.entityDescription && this.fieldKey) {
			return this.entityDescription.find(field => field.key === this.fieldKey);
		}

		return { model: {} };
	}

	async mounted() {
		if ([undefined, true].includes(this.fieldDefinition.shouldLoadManyToOneOptions)) {
			await this.loadManyToOneOptions();
		}
		// this.updateContentOnReadOnly();
		this.updateDateMaskFromEntity();
	}

	inputChange(key: string) {
		if (this.onInputChange && typeof this.onInputChange === "function") {
			this.onInputChange(key, this.fieldKey);
		}
	}

	validationField(value: any) {
		if (this.error) {
			this.error = "";
			this.$emit("updateError", this.fieldKey, true);
		}
		if (!this.fieldDefinition.validationField) {
			return;
		}
		if (this.fieldDefinition.validationField(value)) {
			this.error = "";
			this.$emit("updateError", this.fieldKey, true);
		} else {
			this.error = this.fieldDefinition.messageError;
			this.$emit("updateError", this.fieldKey, false);
		}
	}

	onFieldChange(field: any[] | any) {
		this.entity[this.fieldDefinition.key] = field;
	}

	@Watch("fieldDefinition")
	async fieldDefinitionChanged() {
		await this.loadManyToOneOptions();
	}

	async loadManyToOneOptions() {
		if (this.fieldDefinition.kind === "relation" && this.fieldDefinition.model) {
			await this.fieldDefinition.model.loadManyToOneOptions();
		}
	}

	async onCopyFileNameButton() {
		if (
			!process.env.VUE_APP_GCLOUD_BUCKET ||
			!process.env.VUE_APP_GCLOUD_PUBLIC_URL ||
			!this.entity[this.fieldDefinition.key]
		) {
			throw new Error("Não encontrado");
		}

		const fileUrl = `https://${process.env.VUE_APP_GCLOUD_PUBLIC_URL}/${process.env.VUE_APP_GCLOUD_BUCKET}/${
			this.entity[this.fieldDefinition.key]
		}`;

		if (navigator.clipboard) {
			try {
				await navigator.clipboard.writeText(fileUrl);

				showSuccessAlert("Mídia copiada com sucesso!");
			} catch (error) {
				showErrorAlert("Ocorreu um erro, tente novamente.");
			}
		}
	}

	@Watch("file")
	async fileChanged() {
		this.imagePreview = "";

		if (!this.fieldDefinition.key) {
			return;
		}
		// limpa o arquivo se ele foi removido
		if (!this.file) {
			this.entity[this.fieldDefinition.key] = null;
			return;
		}
		// faz o upload e atualiza a entidade com a URL
		this.$store.dispatch("app/showLoading");
		try {
			const fileResponse = await uploadFile(this.file);
			this.entity[this.fieldDefinition.key] = fileResponse?.fileName;
			this._getImagePreview(this.file);
		} catch (error: any) {
			this.file = null;
			this.entity[this.fieldDefinition.key] = null;
			showErrorAlert(error?.message);
		}
		this.$store.dispatch("app/hideLoading");
	}

	async _getImagePreview(file: File) {
		let me = this;
		let reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			me.imagePreview = reader.result;
		};
	}

	openFile(fileUrl: string) {
		window.open(fileUrl, "_blank");
	}

	get fieldReadOnly() {
		if (this.fieldDefinition.isReadOnly) {
			return this.fieldDefinition.isReadOnly;
		} else {
			return this.isReadOnly;
		}
	}
	// Função utilizada em casos que o valor retornado do back é apenas o ID, mas é necessário renderizar o nome da entidade
	transformFieldKeyValue(fieldDefinitionKey: string) {
		if (fieldDefinitionKey && fieldDefinitionKey.includes("id")) {
			let replaceString = fieldDefinitionKey.replace(/id/g, "name");
			return replaceString;
		} else {
			return fieldDefinitionKey;
		}
	}

	get entityValue() {
		return this.entity[this.fieldDefinition.key];
	}

	// Date Mask
	dateMaskValue = "";

	@Watch("entityValue")
	updateDateMaskFromEntity() {
		if (this.fieldDefinition.kind === "datemask" && this.entityValue) {
			this.dateMaskValue = format(parseISO(this.entity[this.fieldDefinition.key]), "dd/MM/yyyy");
		}
	}

	@Watch("dateMaskValue")
	updateEntityFromDateMask() {
		if (this.dateMaskValue?.length !== 10) {
			return;
		}
		try {
			this.entity[this.fieldDefinition.key] = formatISO(
				parse(this.dateMaskValue, "dd/MM/yyyy", new Date()).setHours(12),
			);
		} catch {
			showErrorAlert("Data inválida. Digite novamente no formato dd/mm/aaaa.");
			this.entity[this.fieldDefinition.key] = null;
			this.dateMaskValue = "";
		}
	}
}
